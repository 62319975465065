
import { defineComponent, onMounted, ref } from "vue";
import { useCart } from "@/store/useCart";
import { useAsyncSpinner } from "@/services/Spinner";
import { MemberApi } from "@/services/Api";
import FormControlSelect from "@/components/FormControlSelect.vue";
import { Option } from "@/models/Option";

export default defineComponent({
  components: {
    FormControlSelect
  },
  props: {
    checkout: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const companyId = ref<string>();
    const companies = ref<Option[]>();
    const cart = useCart();
    
    onMounted(async () => {
      await useAsyncSpinner(async () => {
        const model = await MemberApi.getCompanies();
        companyId.value = model.companyId;
        companies.value = model.companies;
      });
    });

    const changeCompany = async (): Promise<void> => {
      await useAsyncSpinner(async () => {
        await MemberApi.setCompany(companyId.value as string);
        
        if (props.checkout) {
          location.reload();
        } else {
          await cart.load();
        }
      });
    };

    return {
      companyId,
      companies,
      changeCompany
    }
  }
});
