
import { defineComponent, PropType, ref } from "vue";
import { Customer } from "@/models/Customer";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    source: {
      type: Object as PropType<Customer>,
      required: true
    },
    shipTo: {
      type: Boolean,
      required: false,
      default: false
    },
    buyer: {
      type: Boolean,
      required: false,
      default: false
    },
    payer: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: { 
    updated (customer: Customer): boolean {
      return customer != null;
    }
  },
  setup(props, context) {
    const customer = ref<Customer>(props.source);

    const onSubmit = (): void => {
      context.emit("updated", customer);
    };

    return {
      customer,
      onSubmit
    };
  }
});
