
import {defineComponent, PropType} from "vue";

import {Category} from "@/models/Category";

export default defineComponent({
  props:{
    categories:{
      type:Array as PropType<Array<Category>>,
      required:true
    }
  }
});
