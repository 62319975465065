import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22fd4f65"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "quantity"
}
const _hoisted_2 = {
  key: 1,
  class: "container p-0"
}
const _hoisted_3 = { class: "row justify-content-between quantity quantity--alternative" }
const _hoisted_4 = { class: "col-4 p-0" }
const _hoisted_5 = { class: "col-4 p-0" }
const _hoisted_6 = { class: "col-4 p-0" }
const _hoisted_7 = {
  key: 2,
  class: "row justify-content-center quantity quantity--mode-2"
}
const _hoisted_8 = {
  key: 0,
  class: "col-auto col-md-12 col-xl-auto p-0"
}
const _hoisted_9 = { class: "col-auto col-md-12 col-xl-auto p-0" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = {
  key: 1,
  class: "col-auto col-md-12 col-xl-auto p-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.mode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("input", {
            type: "button",
            value: "-",
            class: "minus quantity__minus",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSubtractOne()))
          }),
          _withDirectives(_createElementVNode("input", {
            type: "number",
            name: "quantity",
            class: "qty quantity__input",
            size: "4",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelNumber) = $event)),
            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onChange($event)))
          }, null, 544), [
            [_vModelText, _ctx.modelNumber]
          ]),
          _createElementVNode("input", {
            type: "button",
            value: "+",
            class: "plus quantity__plus",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onAddOne()))
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.mode == 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("input", {
                type: "button",
                value: "-",
                class: "minus quantity__minus",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onSubtractOne()))
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _withDirectives(_createElementVNode("input", {
                type: "number",
                name: "quantity",
                class: "qty quantity__input quantity__input--full-width",
                size: "4",
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modelNumber) = $event)),
                onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onChange($event)))
              }, null, 544), [
                [_vModelText, _ctx.modelNumber]
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("input", {
                type: "button",
                value: "+",
                class: "plus quantity__plus",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onAddOne()))
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.mode==2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (!_ctx.disabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("input", {
                  type: "button",
                  value: "-",
                  class: "minus quantity__minus",
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onSubtractOne()))
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, [
            _withDirectives(_createElementVNode("input", {
              type: "number",
              name: "quantity",
              class: "qty quantity__input",
              size: "4",
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.modelNumber) = $event)),
              onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.onChange($event))),
              disabled: _ctx.disabled
            }, null, 40, _hoisted_10), [
              [_vModelText, _ctx.modelNumber]
            ])
          ]),
          (!_ctx.disabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("input", {
                  type: "button",
                  value: "+",
                  class: "plus quantity__plus",
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.onAddOne()))
                })
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}