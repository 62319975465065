import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { Problem } from "@/models/Error";

const instance = axios.create({
  baseURL: "/umbraco/api/"
});

const handleError = (error: AxiosError<Problem>) => {
  if (error.response) {
    return Promise.reject(error.response.data);
  } else if (error.request) {
    const details: Problem = {
      detail: error.message,
      status: error.request.status,
      title: error.name
    };
    return Promise.reject(details);
  }
  const details: Problem = {
    detail: error.message,
    status: 500,
    title: error.name
  };
  return Promise.reject(details);
};

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  const culture = (document.getElementById("app") as HTMLElement).getAttribute("data-culture");
  Object.assign(config.headers?.common, config.headers?.common, {"X-Requested-With": "XMLHttpRequest", "Accept-Language": culture});
  return config;
}, handleError);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
instance.interceptors.response.use((response: AxiosResponse<any>) => {
  return response.data;
}, handleError);

export default instance;