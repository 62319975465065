
import {defineComponent} from "vue";
import BaseBox from "@/components/BaseBox.vue";

export default defineComponent({
  components: {BaseBox},
  props: {
    title: {
      type: String,
      required: true
    }
  }
});
