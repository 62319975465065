import { computed, defineComponent, onMounted, ref } from "vue";
import { MemberApi } from "@/services/Api";
import { useAsyncSpinner } from "@/services/Spinner";
import { Order, OrderList } from "@/models/Order";
import CustomPagination from "@/components/CustomPagination.vue";
import { Pagination, PaginationItem } from "@/models/Pagination";
import { BasketApi } from "@/services/Api";
import { useCart } from "@/store/useCart";

export default defineComponent({
  components: {
    CustomPagination,
  },
  setup() {
    let currentPage = 1;
    const history = ref<OrderList>();
    const order = ref<Order>();
    const processing = ref<boolean>(false);
    const showHistory = computed<boolean>(
      () => history.value !== undefined && order.value === undefined
    );
    const cart = useCart();

    const pagination = computed<Pagination>(() => {
      const pagination: Pagination = {
        maxShow: 25,
        items: [] as Array<PaginationItem>,
      };
      if (history.value === undefined) {
        return pagination;
      }
      const items = [] as Array<PaginationItem>;
      for (let i = 1; i <= history.value.totalPages; i++) {
        items.push({ name: i.toString(), value: i.toString() });
      }
      pagination.items = items;
      return pagination;
    });

    const loadHistory = async (): Promise<void> => {
      await useAsyncSpinner(async () => {
        history.value = await MemberApi.getOrderHistory(currentPage);
      });
    };

    const onPaginationClick = async (
      _: number,
      value: string
    ): Promise<void> => {
      currentPage = parseInt(value, 10);
      await loadHistory();
    };

    const showOrder = (showOrder: Order): void => {
      order.value = showOrder;
    };

    const hideOrder = (): void => {
      order.value = undefined;
    };

    const orderAgain = async (again: Order): Promise<void> => {
      processing.value = true;
      await useAsyncSpinner(async () => {
        await BasketApi.createFromOrder(again.basketId);
        await cart.load();
        processing.value = false;
      });
    };

    onMounted(async () => {
      await loadHistory();
    });

    return {
      history,
      order,
      pagination,
      showHistory,
      processing,
      onPaginationClick,
      showOrder,
      hideOrder,
      orderAgain,
    };
  },
});
