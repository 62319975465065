
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    type: {
      type: String,
      required: false,
      default: "button"
    }
  },
  emits: {
    click: () => true
  },
  methods: {
    click() {
      if (this.type !== "submit") {
        this.$emit("click");
      }
    }
  }
});
