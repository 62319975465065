import axios from "@/axios";
import Swal from "sweetalert2";
import { ProductFilter, ProductFilterMap, ProductList } from "@/models/ProductList";
import { Cart } from "@/models/Cart";
import { Login, ChangePassword } from "@/models/Member";
import { Problem } from "@/models/Error";
import { Checkout } from "@/models/Checkout";
import { ExtraInfo, Payment } from "@/models/Payment";
import { CompanyModel } from "@/models/Customer";
import { OrderList } from "@/models/Order";

const getFilters = async(): Promise<ProductFilter[]> => {
  try {
    return await axios.get("category/getfilters", { params: { contentId: window.contentId }});
  } catch (e) {
    return Promise.reject(e);
  }
};

const login = async (emailAddress: string, password: string): Promise<Login> => {
  try {
    return await axios.post("member/login", { emailAddress, password, contentId: window.contentId });
  } catch (e) {
    return Promise.reject(e);
  }
};

const changePassword = async (currentPassword: string, password: string, confirmPassword: string): Promise<string> => {
  try {
    return await axios.put("member/changepassword", { currentPassword, password, confirmPassword, contentId: window.contentId });
  } catch (e) {
    return Promise.reject(e);
  }
}

export const ProductApi = {
  getProducts: async (filters: Array<string>, pageNumber: number, sort: string): Promise<ProductList> => {
    try {
      return await axios.get("category/getproducts", { params: { contentId: window.contentId, filters: JSON.stringify(filters), pageNumber, sort }});
    } catch (e) {
      const problem = e as Problem;
      await Swal.fire<void>({
        icon: "error",
        text: problem.detail
      });
      return Promise.reject(e);
    }
  },
  getFilters:  async (): Promise<ProductFilterMap[]> => {
    try {
      const data = await getFilters();
      return data.filter(x => x.type === "parf").map(x => ({
        type: x.type,
        name: x.name,
        options: x.items.map(y => ({id: y.id, type: y.type, name: y.name}))
      }));
    } catch (e) {
      const problem = e as Problem;
      await Swal.fire<void>({
        icon: "error",
        text: problem.detail
      });
      return Promise.reject(e);
    } 
  }
};

export const BasketApi = {
  getBasket: async (fetchEmpty: boolean): Promise<Cart> => {
    try {
      return await axios.get("basket/get", { params: { contentId: window.contentId, fetchEmpty }});
    }
    catch (e) {
      const problem = e as Problem;
      await Swal.fire<void>({
        icon: "error",
        text: problem.detail
      });
      return Promise.reject(e);
    }
  },
  addToBasket: async (partNo: string, quantity: number, incrementExisting: boolean): Promise<Cart> => {
    try {
      return await axios.post("basket/addorupdate", { partNo, quantity, incrementExisting, contentId: window.contentId });
    }
    catch (e) {
      const problem = e as Problem;
      await Swal.fire<void>({
        icon: "error",
        text: problem.detail
      });
      return Promise.reject(e);
    }
  },
  updateBasket: async (partNo: string, quantity: number): Promise<Cart> => {
    try {
      return await axios.put("basket/update", { partNo, quantity, contentId: window.contentId });
    }
    catch (e) {
      const problem = e as Problem;
      await Swal.fire<void>({
        icon: "error",
        text: problem.detail
      });
      return Promise.reject(e);
    }
  },
  removeFromBasket: async (partNo: string): Promise<Cart> => {
    try {
      return await axios.delete("basket/remove", { params: { partNo, contentId: window.contentId }});
    }
    catch (e) {
      const problem = e as Problem;
      await Swal.fire<void>({
        icon: "error",
        text: problem.detail
      });
      return Promise.reject(e);
    }
  },
  createFromOrder: async(basketId: string): Promise<Cart> => {
    try {
      return await axios.post("basket/createfromorder", null, { params: { basketId, contentId: window.contentId }});
    }
    catch (e) {
      const problem = e as Problem;
      await Swal.fire<void>({
        icon: "error",
        text: problem.detail
      });
      return Promise.reject(e);
    }
  }
};

export const CheckoutApi = {
  getCheckout: async(): Promise<Checkout> => {
    try {
      return await axios.get("checkout/get", { params: { contentId: window.contentId }});
    }
    catch (e) {
      const problem = e as Problem;
      await Swal.fire<void>({
        icon: "error",
        text: problem.detail
      });
      return Promise.reject(e);
    }
  },
  setPayment: async(paymentMethodId: string): Promise<Checkout> => {
    try {
      return await axios.put("checkout/setpayment", null, { params: { paymentMethodId, contentId: window.contentId }});
    }
    catch (e) {
      const problem = e as Problem;
      await Swal.fire<void>({
        icon: "error",
        text: problem.detail
      });
      return Promise.reject(e);
    }
  },
  setDelivery: async(deliveryMethodId: string): Promise<Checkout> => {
    try {
      return await axios.put("checkout/setdelivery", null, { params: { deliveryMethodId, contentId: window.contentId }}); 
    }
    catch (e) {
      const problem = e as Problem;
      await Swal.fire<void>({
        icon: "error",
        text: problem.detail
      });
      return Promise.reject(e);
    }
  },
  saveOrder: async(extraInfo: ExtraInfo[]): Promise<Payment> => {
    try {
      return await axios.post("checkout/saveorder", extraInfo, { params: { contentId: window.contentId }});
    }
    catch (e) {
      const problem = e as Problem;
      await Swal.fire<void>({
        icon: "error",
        text: problem.detail
      });
      return Promise.reject(e);
    }
  }
};

export const MemberApi = {
  getCompanies: async(): Promise<CompanyModel> => {
    try {
      return await axios.get("member/getcompanies", { params: { contentId: window.contentId }});  
    }
    catch (e) {
      const problem = e as Problem;
      await Swal.fire<void>({
        icon: "error",
        text: problem.detail
      });
      return Promise.reject(e);
    }
  },
  setCompany: async(companyId: string): Promise<void> => {
    try {
      await axios.put("member/setcompany", { companyId, contentId: window.contentId });  
    }
    catch (e) {
      const problem = e as Problem;
      await Swal.fire<void>({
        icon: "error",
        text: problem.detail
      });
      return Promise.reject(e);
    }
  },
  getOrderHistory: async(pageNo: number): Promise<OrderList> => {
    try {
      return axios.get("member/getorderhistory", { params: { pageNo, contentId: window.contentId }});
    }
    catch (e) {
      const problem = e as Problem;
      await Swal.fire<void>({
        icon: "error",
        text: problem.detail
      });
      return Promise.reject(e);
    }
  },
  login: async (emailAddress: string, password: string): Promise<Login> => {
    try {
      const member = await login(emailAddress, password);
      member.success = true;
      return member;
    } catch (e) {
      const error = e as Problem;
      const member: Login = {
        name: "",
        success: false,
        messages: error.messages ?? error.detail?.split("|"),
      };
      return member;
    }
  },
  logout: async (): Promise<boolean> => {
    try {
      await axios.get("member/logout", { params: { contentId: window.contentId }});
      return true;
    } catch (e) {
      const problem = e as Problem;
      await Swal.fire<void>({
        icon: "error",
        text: problem.detail
      });
      return false
    }
  },
  changePassword: async(currentPassword: string, password: string, confirmPassword: string): Promise<ChangePassword> => {
    try {
      const message = await changePassword(currentPassword, password, confirmPassword);
      const change: ChangePassword = {
        success: true,
        message: message
      };
      return change;
    }
    catch (e) {
      const error = e as Problem;
      const change: ChangePassword = {
        success: false,
        messages: error.messages ?? error.detail?.split("|"),
      };
      return change;
    }
  }
};