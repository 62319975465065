import { createI18n, LocaleMessages, VueMessageType } from "vue-i18n";
import type { I18n, Locale, I18nOptions, Composer } from "vue-i18n";
import { DateTimeFormats, NumberFormats } from "@intlify/core-base";
import { setLocale } from "@vee-validate/i18n";

const loadedLanguages: string[] = [];

function setLanguage(i18n: I18n<LocaleMessages<VueMessageType> | undefined, DateTimeFormats | undefined, NumberFormats | undefined, boolean>, locale: Locale): void {
  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    (i18n.global as Composer).locale.value = locale;
  }
}

export function setupI18n(options: I18nOptions): I18n<LocaleMessages<VueMessageType> | undefined, DateTimeFormats | undefined, NumberFormats | undefined, boolean> {
  return createI18n(options);
}

export function loadLanguageAsync(i18n: I18n<LocaleMessages<VueMessageType> | undefined, DateTimeFormats | undefined, NumberFormats | undefined, boolean>, locale: Locale, translate: Record<string, string>): void {
  if (loadedLanguages.includes(locale)) {
    if (i18n.global.locale !== locale) {
      setLanguage(i18n, locale);
      setLocale(locale);
    }
    return;
  }

  i18n.global.setLocaleMessage(locale, translate);
  setLanguage(i18n, locale);
  setLocale(locale);
  loadedLanguages.push(locale);
}