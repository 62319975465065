
import {defineComponent, ref} from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: Number,
      required: true
    },
    mode:{
      type:Number,
      required:false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  emits: {
    "update:modelValue" (value: number): boolean {
      return !isNaN(value);
    }
  },
  setup(props, context) {
    const modelNumber = ref<number>(props.modelValue);

    const onAddOne = (): void => {
      modelNumber.value += 1;
      context.emit("update:modelValue", modelNumber.value);
    };

    const onSubtractOne = (): void => {
      if (modelNumber.value > 1) {
        modelNumber.value -= 1;
      }
      context.emit("update:modelValue", modelNumber.value);
    };

    const onChange = (e: InputEvent) => {
      const value = Number((e.currentTarget as HTMLInputElement).value);
      if (isNaN(value)) {
        context.emit("update:modelValue", 1);
      }
      else{
        context.emit("update:modelValue", value);
      }
    };

    return {
      modelNumber,
      onAddOne,
      onSubtractOne,
      onChange
    }
  }
});
