import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "widget" }
const _hoisted_2 = { class: "widget_title" }
const _hoisted_3 = { class: "list_brand" }
const _hoisted_4 = { class: "custome-checkbox" }
const _hoisted_5 = ["id", "value", "onClick"]
const _hoisted_6 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.filter.name), 1),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filter.options, (option) => {
        return (_openBlock(), _createElementBlock("li", {
          key: option.name
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("input", {
              class: "form-check-input",
              type: "checkbox",
              name: "checkbox",
              id: (_ctx.filter.name + '-' + option.name),
              value: option.name,
              onClick: ($event: any) => (_ctx.onFilterClick(option.id))
            }, null, 8, _hoisted_5),
            _createElementVNode("label", {
              class: "form-check-label",
              for: (_ctx.filter.name + '-' + option.name)
            }, [
              _createElementVNode("span", null, _toDisplayString(option.name), 1)
            ], 8, _hoisted_6)
          ])
        ]))
      }), 128))
    ])
  ]))
}