import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b11a0c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "divider" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.iconClass)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass(['divider__icon', _ctx.iconClass])
        }, null, 2))
      : _createCommentVNode("", true)
  ]))
}