
import {defineComponent, PropType} from 'vue';

import {Pagination} from "@/models/Pagination";

export default defineComponent({
  props: {
    pagination: {
      type: Object as PropType<Pagination>,
      required: true
    },
    activeItem: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      activeItemInternal: this.activeItem,
      maxShowInternal: this.pagination.maxShow
    };
  },
  emits: {
    "on-pagination-click" (index: number, value: string): boolean {
      return index !== -1 && value !== "";
    }
  },
  methods: {
    paginationItemClick(itemIndex: number) {
      if (this.activeItemInternal !== itemIndex) {
        this.activeItemInternal = itemIndex;
        const item = this.pagination.items[this.activeItemInternal];
        this.$emit("on-pagination-click", itemIndex, item.value);
        window.scrollTo(0, 0);
      }
    },
    paginationPreviousClick() {
      this.paginationItemClick(this.activeItemInternal - 1);
    },
    paginationNextClick() {
      this.paginationItemClick(this.activeItemInternal + 1);
    },
    showPrevious() {
      if (this.pagination.maxShow) {
        return this.pagination.maxShow <= this.activeItemInternal
      }
    },
    showPaginationItem(index: number) {
      if (this.pagination.maxShow) {
        return index >= Math.min(this.activeItemInternal - this.pagination.maxShow + 1, this.pagination.items.length - this.pagination.maxShow + 1)
            && index <= Math.min(this.activeItemInternal + this.pagination.maxShow - 1, this.pagination.items.length - 1);
      }
    },
    showNext() {
      if (this.pagination.maxShow) {
        return this.activeItemInternal + this.pagination.maxShow < this.pagination.items.length;
      }
    }
  }
});
