import { defineComponent, PropType } from "vue";
import { mapActions } from "pinia";

import { Product } from "@/models/Product";
import BuyButton from "@/components/BuyButton.vue";
import FormControlQuantity from "@/components/FormControlQuantity.vue";
import { useCart } from "@/store/useCart";
import AdditionalInfoTable from "@/components/AdditionalInfoTable/AdditionalInfoTable.vue";
import ProductImages from "@/components/ProductImages.vue";
import FormControlSelect from "@/components/FormControlSelect.vue";
import { Option } from "@/models/Option";

export default defineComponent({
  components: {
    FormControlSelect,
    ProductImages,
    AdditionalInfoTable,
    FormControlQuantity,
    BuyButton,
  },
  data() {
    return { quantity: 1 };
  },
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    categoryurl: {
      type: String,
      required: true,
    },
    categoryname: {
      type: String,
      required: true,
    },
  },
  computed: {
    variants() {
      if (this.product && this.product.variants) {
        return this.product.variants.map((x) => {
          const option: Option = {
            name: x.name,
            value: x.url,
            selected: x.isPrimary,
          };
          return option;
        });
      }
      return [];
    },
    stock() {
      if (this.product.stock) {
        if (this.product.stock == 0) {
          return "stock--lvl0";
        }
        if (this.product.stock < 10) {
          return "stock--lvl1";
        }
        if (this.product.stock < 50) {
          return "stock--lvl2";
        }
        return "stock--lvl3";
      }

      return "stock--lvl0";
    },
  },
  methods: {
    async onBuyButtonClick() {
      if (this.product && this.product.sku) {
        this.addToCart(this.product.sku, this.quantity, false);
      }
    },
    onSelectVariant(value) {
      location.assign(value);
    },
    ...mapActions(useCart, ["addToCart"]),
  },
});
