import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-034b4995"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row justify-content-center" }
const _hoisted_4 = { class: "col-md-6" }
const _hoisted_5 = { class: "heading_s1 text-center" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_list = _resolveComponent("product-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_product_list, { products: _ctx.products }, null, 8, ["products"])
        ])
      ])
    ])
  ]))
}