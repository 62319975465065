import { Spinner, SpinnerOptions } from "spin.js";
const element = document.querySelector("#app") as HTMLElement;
 
export const useAsyncSpinner = async (callback: (() => Promise<void>), opts?: SpinnerOptions | undefined): Promise<void> => {
  let spinner = new Spinner(opts);
  const timeout = setTimeout(() => {
    spinner = spinner.spin(element);
  }, 2000);
  try {
    await callback();
  }
  finally {
    if (timeout) {
      clearTimeout(timeout);
    }
    spinner = spinner.stop();
  }
};

export const useSyncSpinner = (callback: () => void,  opts?: SpinnerOptions | undefined): void => {
  let spinner = new Spinner(opts);
  const timeout = setTimeout(() => {
    spinner = spinner.spin(element);
  }, 2000);
  try {
    callback();
  }
  catch (e) {
    console.info(e);
  }
  finally {
    if (timeout) {
      clearTimeout(timeout);
    }
    spinner.stop();
  }
};