
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    iconClass: {
      type: String,
      required: false
    }
  }
});
