import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "widget" }
const _hoisted_2 = { class: "widget_title" }
const _hoisted_3 = { class: "widget_categories" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "categories_name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.$t("Common.Categories")), 1),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
        return (_openBlock(), _createElementBlock("li", {
          key: category.id
        }, [
          _createElementVNode("a", {
            href: category.url
          }, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(category.name), 1)
          ], 8, _hoisted_4)
        ]))
      }), 128))
    ])
  ]))
}