import { defineComponent, PropType } from "vue";
import { ParametricGroup } from "@/models/ParametricGroup";

export default defineComponent({
  props: {
    group: {
      type: Object as PropType<ParametricGroup>,
      required: true
    }
  },
});