
import {defineComponent, PropType} from "vue";
import {Option} from "@/models/Option";

export default defineComponent({
  data() {
    return {
      isOpen: false
    }
  },
  props: {
    options: {
      type: Array as PropType<Array<Option>>,
      required: true,
    },
    navigateonclick: {
      type: Boolean,
      required: false
    },
    modelValue: {}
  },
  emits: {
    "change"(value: string): boolean {
      return value !== "";
    },
    "update:modelValue"(value: string): boolean {
      return value !== "";
    }
  },
  computed: {
    selectedOption() {
      if (this.options == undefined) {
        const option: Option = {
          name: "",
          value: "",
          selected: false
        }
        return option;
      }
      let option = this.options.find(x => x.selected);
      if (!option) {
        option = this.options[0];
        option.selected = true;
      }
      return option;
    }
  },
  methods: {
    onSelectClick() {
      this.toggleOpen();
    },
    onOptionClick(option: Option) {
      if (!option.selected) {
        this.options.forEach(x => x.selected = false);
        option.selected = true;
        this.$emit("update:modelValue", option.value);
        this.$emit("change", option.value);

        if (this.navigateonclick) {
          window.location.href = option.value;
        }
      }
      this.close();
    },
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
    clickedOutside(ev: Event) {
      if (this.$el != ev.target && !this.$el.contains(ev.target)) {
        this.close();
      }
    },
    getFlag(country: string) {
      switch (country) {
        case "Sverige":
          return "flag--sweden";
        case "United Kingdom":
          return "flag--uk";
        default:
          return null;
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.clickedOutside);
  },
  unmounted() {
    document.removeEventListener("click", this.clickedOutside);
  }
});
