
import {defineComponent, PropType} from "vue";

import ProductTile from "@/components/ProductTile.vue";
import {Product} from "@/models/Product";

export default defineComponent({
  components: {
    ProductTile
  },
  props: {
    products: {
      type: Array as PropType<Array<Product>>,
      required: true,
    }
  }
});
