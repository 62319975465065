import { defineComponent, PropType } from "vue";

import ProductList from "@/components/ProductList.vue";
import CategoryList from "@/components/CategoryList.vue";
import CustomFilter from "@/components/CustomFilter.vue";
import CustomPagination from "@/components/CustomPagination.vue";
import { Category } from "@/models/Category";
import { Filter, FilterOption } from "@/models/Filter";
import { ProductApi } from "@/services/Api";
import { Pagination, PaginationItem } from "@/models/Pagination";
import FormControlSelect from "@/components/FormControlSelect.vue";
import { ProductSorting } from "@/models/ProductSorting";
import { Option } from "@/models/Option";
import { useAsyncSpinner } from "@/services/Spinner";
import { ProductTile } from "@/models/ProductList";

export default defineComponent({
  components: {
    ProductList,
    CategoryList,
    CustomFilter,
    CustomPagination,
    FormControlSelect,
  },
  data() {
    return {
      filters: [] as Array<Filter>,
      currentFilter: [] as Array<string>,
      products: [] as Array<ProductTile>,
      pagination: {} as Pagination,
      currentPage: 0,
      sorting: ProductSorting.PopularityRankAsc,
      sortOptions: Object.entries(ProductSorting).map((x) => {
        const option: Option = {
          name: this.$t("CategoryPage." + x[0]),
          value: x[0],
          selected: false,
        };
        return option;
      }),
    };
  },
  props: {
    categories: {
      type: Array as PropType<Array<Category>>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
  },
  methods: {
    async onPaginationClick(index: number, value: string) {
      this.currentPage = parseInt(value);
      await this.reloadProducts();
    },
    async onFilterClick(filter: Filter, filterOption: FilterOption) {
      const filterItem = `${filter.type}|${filterOption.type}|${filterOption.id}`;
      const indexOfFilter = this.currentFilter.indexOf(filterItem);
      if (indexOfFilter != -1) {
        this.currentFilter.splice(indexOfFilter, 1);
      } else {
        this.currentFilter.push(
          `${filter.type}|${filterOption.type}|${filterOption.id}`
        );
      }
      await this.reloadProducts();
    },
    async reloadProducts() {
      await useAsyncSpinner(async () => {
        const res = await ProductApi.getProducts(
          this.currentFilter,
          this.currentPage,
          ProductSorting[this.sorting]
        );
        this._mapPagination(res);
        this.products = res.products;
      });
    },
    _mapPagination(res) {
      const items = [] as Array<PaginationItem>;
      for (let i = 1; i <= res.numberOfPages; i++) {
        items.push({ name: i.toString(), value: i.toString() });
      }
      this.pagination = { maxShow: 3, items: items } as Pagination;
    },
  },
  mounted() {
    (async () => {
      this.filters = await ProductApi.getFilters();
      await this.reloadProducts();
    })();
  },
});
