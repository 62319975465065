
import {computed, defineComponent, ref} from "vue";

import BaseButton from "@/components/BaseButton.vue";
import { MemberApi } from "@/services/Api";
import { useAsyncSpinner } from "@/services/Spinner";

export default defineComponent({
  components: {
    BaseButton
  },
  setup() {
    const emailAddress = ref<string>();
    const password = ref<string>();
    const errorMessage = ref<string>();
    const hasError = computed<boolean>(() => errorMessage.value !== undefined && errorMessage.value !== "");

    const onSubmit = async(): Promise<void> => {
      errorMessage.value = undefined;
      const urlParams = new URLSearchParams(window.location.search);
      const redirect = urlParams.get("redir");
      
      await useAsyncSpinner(async () =>  {
        if (emailAddress.value == undefined || password.value == undefined) {
          return;
        }
        const model = await MemberApi.login(emailAddress.value, password.value);
        if (model.success) { 
          if (redirect) {
            location.assign(redirect);
          } else {
            location.reload();
          }
        } else {
          errorMessage.value = model.messages?.join("<br />")
        }
      });      
    };

    return {
      emailAddress,
      password,
      hasError,
      errorMessage,
      onSubmit
    };
  }
});
