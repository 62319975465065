
import {defineComponent} from "vue";
import {mapState, mapActions} from "pinia";

import {useCart} from "@/store/useCart";

export default defineComponent({
  props: {
    url: {
      type: String,
      required: true
    }
  },
  computed: {
    hasItems() {
      return this.numberOfItems > 0;
    },
    ...mapState(useCart, ["numberOfItems", "items", "summary"])
  },
  methods: {
    async onRemove(partNo){
      await this.removeFromCart(partNo);
    },
    ...mapActions(useCart, ["load", "removeFromCart"]),
  },
  mounted() {
    this.load();
  }
});
