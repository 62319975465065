
import {defineComponent, onMounted, PropType, ref} from "vue";
import {Image} from "@/models/Image";
import {Breakpoints, Carousel, CarouselConfig, Navigation, Slide} from "vue3-carousel";
import InnerImageZoom from "vue-inner-image-zoom";

export default defineComponent({
  props: {
    images: {
      type: Array as PropType<Array<Image>>
    }
  },
  components: {
    Carousel,
    Navigation,
    Slide,
    InnerImageZoom
  },
  setup(props) {
    const displayImage = ref<Image>();

    const settings: CarouselConfig = {
      itemsToShow: 1,
      snapAlign: "center"
    };

    const breakpoints: Breakpoints = {
      0: { // 0px and up
        itemsToShow: 1,
        snapAlign: "center",
      },
      700: { // 700px and up
        itemsToShow: 2,
        snapAlign: "center",
      },
      // 1024 and up
      1024: {
        itemsToShow: 4,
        snapAlign: "start",
      }
    };

    const imageClick = (image: Image): void => {
      displayImage.value = image;
    };

    onMounted(() => {
      if (props.images && props.images.length > 0) {
        displayImage.value = props.images[0];
      }
    });

    return {
      displayImage,
      imageClick,
      settings,
      breakpoints
    };
  }
});
