import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "product-image" }
const _hoisted_2 = {
  key: 0,
  class: "product_img_box"
}
const _hoisted_3 = { class: "carousel__item" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inner_image_zoom = _resolveComponent("inner-image-zoom")!
  const _component_Slide = _resolveComponent("Slide")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.displayImage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_inner_image_zoom, {
            src: _ctx.displayImage.url,
            zoomSrc: _ctx.displayImage.url,
            alt: _ctx.displayImage.alt,
            hideHint: true
          }, null, 8, ["src", "zoomSrc", "alt"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Carousel, {
      settings: _ctx.settings,
      breakpoints: _ctx.breakpoints
    }, {
      addons: _withCtx(() => [
        _createVNode(_component_Navigation)
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
          return (_openBlock(), _createBlock(_component_Slide, {
            key: image.id
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("a", {
                  href: "#",
                  onClick: _withModifiers(($event: any) => (_ctx.imageClick(image)), ["prevent"])
                }, [
                  _createElementVNode("img", {
                    src: image.url,
                    alt: image.alt
                  }, null, 8, _hoisted_5)
                ], 8, _hoisted_4)
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["settings", "breakpoints"])
  ]))
}