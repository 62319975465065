import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_control_select = _resolveComponent("form-control-select")!

  return (_ctx.companies && _ctx.companies.length > 1)
    ? (_openBlock(), _createBlock(_component_form_control_select, {
        key: 0,
        onChange: _ctx.changeCompany,
        modelValue: _ctx.companyId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.companyId) = $event)),
        options: _ctx.companies
      }, null, 8, ["onChange", "modelValue", "options"]))
    : _createCommentVNode("", true)
}