
import {defineComponent, computed} from "vue";

import FormControlQuantity from "@/components/FormControlQuantity.vue";
import {useCart} from "@/store/useCart";
import {CartRow} from "@/models/Cart";

export default defineComponent({
  components: {
    FormControlQuantity
  },
  emits: {
    updateCheckout: () => true
  },
  setup(props, context) {
    const cart = useCart();
    const items = computed<CartRow[]>(() => cart.items as CartRow[]);

    const onQuantity = async (value: number, partNo: string): Promise<void> => {
      await cart.addToCart(partNo, value, false);
      context.emit("updateCheckout");
    };

    const onRemove = async (partNo: string): Promise<void> => {
      await cart.removeFromCart(partNo);
      context.emit("updateCheckout");
    };

    return {
      items,
      onQuantity,
      onRemove
    }
  }
});
