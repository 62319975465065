import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24ebb809"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "table table-bordered" }
const _hoisted_3 = { class: "table__td table__td--key" }
const _hoisted_4 = { class: "table__td table__td--value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.group.name)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(_ctx.group.name), 1))
      : _createCommentVNode("", true),
    _createElementVNode("table", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.group.parametrics, (parametric) => {
        return (_openBlock(), _createElementBlock("tr", {
          class: "table__tr",
          key: parametric.name
        }, [
          _createElementVNode("td", _hoisted_3, _toDisplayString(parametric.name), 1),
          _createElementVNode("td", _hoisted_4, _toDisplayString(parametric.value) + " " + _toDisplayString(parametric.uom), 1)
        ]))
      }), 128))
    ])
  ], 64))
}