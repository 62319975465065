import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10f8e392"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-lg-12" }
const _hoisted_5 = { class: "category-page__header" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-lg-9" }
const _hoisted_8 = { class: "row shop_container" }
const _hoisted_9 = { class: "row mx-0 mx-sm-2" }
const _hoisted_10 = {
  class: "col-sm-12 col-md-5 p-0 mx-sm-3",
  style: {"z-index":"1"}
}
const _hoisted_11 = { class: "col-lg-3 order-lg-first mt-4 pt-2 mt-lg-0 pt-lg-0" }
const _hoisted_12 = { class: "sidebar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_control_select = _resolveComponent("form-control-select")!
  const _component_ProductList = _resolveComponent("ProductList")!
  const _component_CustomPagination = _resolveComponent("CustomPagination")!
  const _component_CategoryList = _resolveComponent("CategoryList")!
  const _component_CustomFilter = _resolveComponent("CustomFilter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.headline), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_form_control_select, {
                  class: "select__container--border",
                  onChange: _ctx.reloadProducts,
                  modelValue: _ctx.sorting,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sorting) = $event)),
                  options: _ctx.sortOptions
                }, null, 8, ["onChange", "modelValue", "options"])
              ])
            ]),
            _createVNode(_component_ProductList, {
              "content-id": _ctx.currentcategoryid,
              products: _ctx.products
            }, null, 8, ["content-id", "products"])
          ]),
          _createVNode(_component_CustomPagination, {
            pagination: _ctx.pagination,
            onOnPaginationClick: _ctx.onPaginationClick
          }, null, 8, ["pagination", "onOnPaginationClick"])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_CategoryList, { categories: _ctx.categories }, null, 8, ["categories"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filter) => {
              return (_openBlock(), _createBlock(_component_CustomFilter, {
                key: filter.id,
                filter: filter,
                onOnFilterClick: _ctx.onFilterClick
              }, null, 8, ["filter", "onOnFilterClick"]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}