import { defineComponent, PropType } from "vue";
import ProductList from "@/components/ProductList.vue";
import { Product } from "@/models/Product";

export default defineComponent({
  components: {
    ProductList
  },
  props: {
    products: {
      type: Array as PropType<Array<Product>>,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
});