import { createApp } from "vue";
import { createPinia } from "pinia";
import FloatingVue from "floating-vue";
import { setupI18n, loadLanguageAsync } from "./i18n";
import { Field, Form, ErrorMessage, configure, defineRule } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import * as rules from "@vee-validate/rules";
import allRules from "@vee-validate/rules";
import sv from "@vee-validate/i18n/dist/locale/sv.json";
import da from "@vee-validate/i18n/dist/locale/da.json";
import no from "@vee-validate/i18n/dist/locale/nb_NO.json";
import en from "@vee-validate/i18n/dist/locale/en.json";
import moment from "moment";

import "bootstrap/scss/bootstrap.scss";
import "spin.js/spin.css";
import "vue3-carousel/dist/carousel.css";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import "sweetalert2/dist/sweetalert2.css";
import "floating-vue/dist/style.css";

import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "@fortawesome/fontawesome-free/scss/regular.scss";
import "@fortawesome/fontawesome-free/scss/solid.scss";

// TODO: convert to VUE?
// import "./scripts/shopwise.js"

// Icons
import "./styles/icons/themify-icons.css";
import "./styles/icons/ionicons.min.css";
import "./styles/icons/linearicons.css";
import "./styles/icons/flaticon.css";
import "./styles/icons/simple-line-icons.css";

import "./styles/animate.css";
import "./styles/app.scss";

const lang = (document.querySelector("html") as HTMLHtmlElement).getAttribute(
  "lang"
) as string;

Object.keys(rules).forEach((rule) => {
  if (rule !== "default") {
    defineRule(rule, allRules[rule]);
  }
});

const i18n = setupI18n({
  globalInjection: true,
  legacy: false,
  locale: lang,
  fallbackLocale: "en",
});

const store = createPinia();

// https://vee-validate.logaretm.com/v4/guide/i18n#overview
configure({
  generateMessage: localize({ en, sv, no, da }),
});

moment.locale(lang);
loadLanguageAsync(i18n, lang, window.translations);

import CategoryPage from "@/pages/CategoryPage/CategoryPage.vue";
import ProductPage from "@/pages/ProductPage/ProductPage.vue";
import ProductListBlock from "@/blocks/ProductListBlock/ProductListBlock.vue";
import MiniCart from "@/components/MiniCart.vue";
import LoginForm from "@/components/LoginForm.vue";
import CheckoutPage from "@/pages/CheckoutPage/CheckoutPage.vue";
import LoginButton from "@/components/LoginButton.vue";
import LogoutButton from "@/components/LogoutButton.vue";
import CartSummary from "@/components/CartSummary.vue";
import CompanySelector from "@/components/CompanySelector.vue";
import OrderHistoryPage from "@/pages/OrderHistoryPage/OrderHistoryPage.vue";
import FormControlSelect from "@/components/FormControlSelect.vue";
import LanguageSelector from "@/components/LanguageSelector.vue";

const app = createApp({})
  .use(FloatingVue)
  .use(i18n)
  .use(store)
  .component("v-form", Form)
  .component("v-field", Field)
  .component("v-message", ErrorMessage)
  .component("category-page", CategoryPage)
  .component("product-page", ProductPage)
  .component("checkout-page", CheckoutPage)
  .component("product-list-block", ProductListBlock)
  .component("mini-cart", MiniCart)
  .component("login-button", LoginButton)
  .component("logout-button", LogoutButton)
  .component("login-form", LoginForm)
  .component("cart-summary", CartSummary)
  .component("company-selector", CompanySelector)
  .component("order-history-page", OrderHistoryPage)
  .component("form-control-select", FormControlSelect)
  .component("language-selector", LanguageSelector);

app.config.globalProperties.$filters = {
  formatDate(value: Date, format = "yyyy-MM-DD HH:mm"): string {
    return moment(value).format(format);
  },
};

app.mount("#app");

document.querySelectorAll("[data-bs-toggle='collapse']").forEach((x) => {
  const target = x.getAttribute("data-bs-target");
  const targetEl = document.querySelector(`${target}`);
  if (targetEl) {
    x.addEventListener("click", () => {
      targetEl.classList.toggle("show");
    });
  }
});
document.querySelectorAll("[data-bs-toggle='dropdown']").forEach((x) => {
  const targetEl = x.nextElementSibling;
  if (targetEl) {
    x.addEventListener("click", () => {
      targetEl.classList.toggle("show");
    });
  }
});
