
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    }
  },
  emits: {
    "update:modelValue"(value: string): boolean {
      return value !== "";
    }
  },
  setup(props, context) {
    const onInput = (ev: InputEvent) => {
      const target = ev.currentTarget as HTMLInputElement;
      if (target) {
        context.emit("update:modelValue", target.value);
      }
    }
    return {
      onInput
    };
  },
});
