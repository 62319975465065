import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f709b79"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-summary" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-6" }
const _hoisted_4 = { class: "order-summary__key order-summary__key--no-margin" }
const _hoisted_5 = { class: "col-5 offset-1" }
const _hoisted_6 = { class: "order-summary__value order-summary__value--no-margin" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-6" }
const _hoisted_9 = { class: "order-summary__key" }
const _hoisted_10 = { class: "col-5 offset-1" }
const _hoisted_11 = { class: "order-summary__value" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-6" }
const _hoisted_14 = { class: "order-summary__key order-summary__key--no-margin" }
const _hoisted_15 = { class: "col-5 offset-1" }
const _hoisted_16 = { class: "order-summary__value order-summary__value--no-margin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("Cart.Total")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.summary.total.amountFormatted), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("Cart.Freight")), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.summary.freight.amountFormatted), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t("Cart.Vat")), 1)
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.summary.total.vatFormatted), 1)
      ])
    ])
  ]))
}