import {defineStore} from "pinia";
import { Cart, ExtraInfoData, CartRow, CartSummary, AmountAndVat } from "@/models/Cart";
import { BasketApi } from "@/services/Api";

export const useCart = defineStore({
  id: "cart",
  state: (): Cart => {
    const items: CartRow[] = [];
    const extraInfo: ExtraInfoData[] = [];
    const amountAndVat: AmountAndVat = {
      amount: 0,
      amountFormatted: "",
      vat: 0,
      vatFormatted: ""
    }
    const summary: CartSummary = {
      fees: amountAndVat,
      freight: amountAndVat,
      items: amountAndVat,
      total: amountAndVat
    };
    const cart: Cart = {
      externalId: "",
      buyable: true,
      discountAmount: 0,
      discountCode: "",
      info: extraInfo,
      items: items,
      numberOfItems: 0,
      promotions: [],
      summary: summary
    };
    return cart;
  },
  actions: {
    async load(fetchEmpty = false): Promise<void> {
      const basket = await BasketApi.getBasket(fetchEmpty);
      this.$patch({...basket});
    },
    async addToCart(partNo: string, quantity: number, incrementExisting: boolean): Promise<void> {
      const basket = await BasketApi.addToBasket(partNo, quantity, incrementExisting);
      this.$patch({...basket});
    },
    async removeFromCart(partNo: string): Promise<void> {
      const basket = await BasketApi.removeFromBasket(partNo);
      this.$patch({...basket});
    }
  }
});