
import {defineComponent, PropType} from "vue";
import {mapActions} from "pinia";

import {Product} from "@/models/Product";
import BuyButton from "@/components/BuyButton.vue";
import {useCart} from "@/store/useCart";

export default defineComponent({
  components: {BuyButton},
  data(){
    return{
      window:window
    }
  },
  props: {
    product: Object as PropType<Product>,
  },
  methods: {
    async buyButtonClick() {
      if (this.product && this.product.sku) {
        await this.addToCart(this.product.sku, 1, true);
      }
    },
    ...mapActions(useCart, ["addToCart"])
  }
});
