
import { defineComponent, PropType} from "vue";
import { CartSummary } from "@/models/Cart";

export default defineComponent({
  props: {
    summary: {
      type: Object as PropType<CartSummary>,
      required: true
    }
  }
});
