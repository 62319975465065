
import { defineComponent } from "vue";
import { MemberApi } from "@/services/Api";
import { useAsyncSpinner } from "@/services/Spinner";

export default defineComponent({
  setup() {
    const click = async(): Promise<void> => {
      await useAsyncSpinner(async () => {
        var success = await MemberApi.logout();
        if (success) { 
          location.reload();
        }
      });
    }
    
    return {
      click
    };
  }
});
