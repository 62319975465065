
import {defineComponent} from "vue";

import BaseButton from "@/components/BaseButton.vue";

export default defineComponent({
  emits: {
    onClick: () => true 
  },
  methods:{
    onClick(){
      this.$emit("onClick");
    }
  },
  components: {BaseButton},
});
