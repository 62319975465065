import { computed, defineComponent, onMounted, ref } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import Cart from "@/components/Cart.vue";
import CartSummary from "@/components/CartSummary.vue";
import CustomerDetails from "@/components/CustomerDetails.vue";
import BoxWithTitle from "@/components/BoxWithTitle.vue";
import BlurOverlay from "@/components/BlurOverlay.vue";
import InputGroup from "@/components/InputGroup.vue";
import Divider from "@/components/Divder.vue";
import LoginForm from "@/components/LoginForm.vue";
import { CheckoutApi } from "@/services/Api";
import { ExtraInfo } from "@/models/Payment";
import { Checkout } from "@/models/Checkout";
import { Customer } from "@/models/Customer";
import { useAsyncSpinner } from "@/services/Spinner";
import FormControlTextArea from "@/components/FormControlTextArea.vue";

export default defineComponent({
  components: {
    Divider,
    InputGroup,
    CustomerDetails,
    BoxWithTitle,
    CartSummary,
    Cart,
    BaseButton,
    LoginForm,
    BlurOverlay,
    FormControlTextArea
  },
  props: {
    loggedIn: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const orderComment = ref<string>("");
    const checkout = ref<Checkout>();
    const agreeToTerms = ref<boolean>(false);
    const isSubmitting = ref<boolean>(false);
    const isBuyable = computed<boolean>(() =>
        props.loggedIn &&
        checkout.value !== undefined &&
        checkout.value.basket.items.length > 0
    );
    const messages = ref<string[] | undefined>();

    const onSubmit = async (): Promise<void> => {
      if (!isBuyable.value) {
        return;
      }
      await useAsyncSpinner(async () => {
        isSubmitting.value = true;

        if (checkout.value) {
          const extraInfo: ExtraInfo[] = [];

          checkout.value.basket.info.forEach((item) => {
            switch (item.code) {
              case "IT_COMMENT":
                if (orderComment.value && orderComment.value !== "") {
                  extraInfo.push({
                    Code: item.code,
                    Value: orderComment.value,
                  });
                }
                break;
            }
          });

          const order = await CheckoutApi.saveOrder(extraInfo);
          messages.value = order.errorMessages;
          if (order.redirectUrl !== null && order.redirectUrl !== "") {
            location.href = order.redirectUrl;
          }
        }

        isSubmitting.value = false;
      });
    };

    const updateCheckout = async (): Promise<void> => {
      await useAsyncSpinner(async () => {
        checkout.value = await CheckoutApi.getCheckout();
      });
    };

    const updateBuyer = (customer: Customer): void => {
      if (checkout.value) {
        checkout.value.buyer = customer;
      }
    };

    const updateShipTo = (customer: Customer): void => {
      if (checkout.value) {
        checkout.value.shipTo = customer;
      }
    };

    const updatePayer = (customer: Customer): void => {
      if (checkout.value) {
        checkout.value.payer = customer;
      }
    };

    const updateDelivery = async (externalId: string): Promise<void> => {
      await useAsyncSpinner(async () => {
        checkout.value = await CheckoutApi.setDelivery(externalId);
      });
    };

    const updatePayment = async (externalId: string): Promise<void> => {
      await useAsyncSpinner(async () => {
        checkout.value = await CheckoutApi.setPayment(externalId);
      });
    };

    const updateAgreeToTerms = (): void => {
      agreeToTerms.value = !agreeToTerms.value;
    };

    onMounted(async () => {
      await useAsyncSpinner(async () => {
        checkout.value = await CheckoutApi.getCheckout();
      });
    });

    return {
      checkout,
      orderComment,
      agreeToTerms,
      onSubmit,
      isSubmitting,
      updateBuyer,
      updateShipTo,
      updatePayer,
      updateDelivery,
      updatePayment,
      updateCheckout,
      updateAgreeToTerms,
      isBuyable,
      messages
    };
  },
});
