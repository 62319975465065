
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    url: {
      type:String,
      required:true
    }
  },
});
