
import {defineComponent, PropType} from "vue";
import {Filter, FilterOption } from "@/models/Filter";

export default defineComponent({
  props: {
    filter: {
      type: Object as PropType<Filter>,
      required: true
    }
  },
  emits: {
    "on-filter-click" (filter: Filter, option: FilterOption | undefined): boolean {
      return filter != null && (option != undefined || option == undefined);
    }
  },
  methods: {
    onFilterClick(id: string) {
      if (this.filter && this.filter.options) {
        this.$emit("on-filter-click", this.filter, this.filter.options.find(y => y.id == id));
      }
    }
  }
});
